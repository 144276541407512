export const photos = [
  {
    title: "Peter Boerboom und Carola Vogt",
    description:
      "Grafikdesigner, Fotokünstler und Illustratoren für die »Federwelt« und den »selfpublisher«",
    imageName: "boerbooms",
  },
  {
    title: "Angelika Fuchs",
    description: "Operations & Distribution",
    imageName: "angelika",
  },
  {
    title: "Anke Gasch",
    description: "Chefredakteurin der »Federwelt«",
    imageName: "anke",
  },
  {
    title: "Heribert Uschtrin",
    description:
      "Herausgeber »Handbuch für Autorinnen und Autoren«, 8. Ausgabe",
    imageName: "heribert",
  },
  {
    title: "Sandra Uschtrin",
    description:
      "Verlegerin und Herausgeberin der »Federwelt« und des »selfpublishers«",
    imageName: "sandra",
  },
]
