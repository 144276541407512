import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import {
  jsx,
  Card,
  Grid,
  Heading,
  Text,
  Styled,
  Link as ThemeUILink,
} from "theme-ui"

import * as content from "../content/ueber-uns.js"
import sandraPortrait from "../images/team/sandra.jpg"

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      image={sandraPortrait}
      location={location}
      title="Über Uns"
      description="Wer wir sind und wo wir herkommen."
    />
    <Heading as="h1" variant="siteTitle">
      Über Uns
    </Heading>
    <Heading as="h2">Die Geschichte eines kleinen Verlages</Heading>
    <Text variant="default">
      Sandra Uschtrin gründete den Uschtrin Verlag im Herbst 1996 mit dem Ziel,
      das »
      <ThemeUILink
        as={Link}
        variant="default"
        to="/buecher/uschtrin-handbuch-fuer-autorinnen-und-autoren"
      >
        Handbuch für Autorinnen und Autoren – Informationen und Adressen aus dem
        deutschen Literaturbetrieb und der Medienbranche
      </ThemeUILink>
      « neu herauszugeben.
    </Text>
    <Text variant="default">
      Die ersten drei Ausgaben des erstmals 1985 publizierten Handbuchs
      erschienen - bereits unter ihrer Regie - im Grafenstein Verlag, München.
      Aufwändige Recherchen sind notwendig, um das Handbuch immer wieder zu dem
      zu machen, was es für viele Autorinnen und Autoren seit jeher ist: »Eine
      Fundgrube an Tipps und Ideen [...], ein unentbehrliches Handwerkszeug für
      jeden, der vom Schreiben lebt« (texten + schreiben) oder davon leben
      möchte.
    </Text>
    <Text variant="default">
      Die derzeit aktuelle 8. Ausgabe ist seit Februar 2015 auf dem Markt und
      kann{" "}
      <ThemeUILink as={Link} variant="default" to="/bestellformular">
        hier bestellt
      </ThemeUILink>{" "}
      werden. Eine 9. Ausgabe ist momentan nicht geplant.
    </Text>
    <Text variant="default">
      Seit 2005 erscheint im Uschtrin Verlag die 1998 von{" "}
      <Styled.a
        href="http://www.titusmueller.de/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Titus Müller
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} /> gegründete{" "}
      <Styled.a
        href="http://www.autorenwelt.de/magazin/federwelt/aktuelles-heft"
        target="_blank"
        rel="noopener noreferrer"
      >
        »Federwelt - Zeitschrift für Autorinnen und Autoren«
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />. Das Fachmagazin erscheint
      alle zwei Monate, hat eine Auflage von 5.000 Exemplaren und ist auch im
      Bahnhofsbuchhandel erhältlich. Chefredakteurin ist{" "}
      <Styled.a
        href="http://www.frohes-schreiben.de/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Anke Gasch
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />.
    </Text>
    <Text variant="default">
      Seit 2016 bringt der Uschtrin Verlag ein weiteres Printmagazin heraus: die
      Fachzeitschrift »
      <Styled.a
        href="https://www.autorenwelt.de/magazin/derselfpublisher/aktuelles-heft"
        target="_blank"
        rel="noopener noreferrer"
      >
        der selfpublisher«
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />. Das Fachblatt wendet sich an
      Selfpublisher und ihre Dienstleister. Erscheinungstermine: 10. März, 10.
      Juni, 10. September, 10. Dezember. Herausgeber des »selfpublishers« ist
      seit 2018 der{" "}
      <Styled.a
        href="https://www.selfpublisher-verband.de/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Selfpublisher-Verband e.V.
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />.
    </Text>
    <Text variant="default">
      Der Uschtrin Verlag betreut außerdem im Auftrag der Autorenwelt GmbH zwei{" "}
      <Styled.a href="https://www.autorenwelt.de/newsletter">
        Newsletter
      </Styled.a>
      . Der Newsletter »Preise & Stipendien« hat rund 25.500 AbonnentInnen. Er
      informiert über aktuelle Ausschreibungen von Literaturwettbewerben und
      -stipendien. Der zweite Newsletter, »News aus dem Literaturbetrieb«, mit
      rund 11.100 AbonnentInnen enthält Branchennews, die für AutorInnen relevant
      sind. Beide Newsletter sind kostenlos und können über die{" "}
      <Styled.a
        href="https://www.autorenwelt.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        Autorenwelt
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} /> bestellt werden.
    </Text>
    <Text variant="default">
      Im Frühjahr 2014 relaunchte der Uschtrin Verlag seine Webpräsenz. Es
      entstanden zwei Internetauftritte: uschtrin.de mit Informationen zum
      Verlag und zu seinen Produkten sowie die Internetplattform
      <Styled.a
        href="https://www.autorenwelt.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        »Autorenwelt«
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} /> mit dynamischen Inhalten rund
      um den Literaturbetrieb. 2015 gründete Sandra Uschtrin zusammen mit
      Angelika Fuchs und Wilhelm Uschtrin die Autorenwelt GmbH.
    </Text>
    <Text variant="default">
      Seit 2016 ist die Autorenwelt mit dem
      <Styled.a
        href="https://shop.autorenwelt.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        »Autorenwelt-Shop«
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} /> online. Beim Autorenwelt-Shop
      handelt es sich um eine Online-Buchhandlung mit einem einzigartigen
      Konzept: AutorInnen erhalten von der Autorenwelt 7 Prozent vom
      (Brutto)Ladenverkaufspreis je verkauftes Buch (zusätzlich zu ihren
      Verlagstantiemen). Es handelt sich also um eine Art
      FairTrade-Buchhandlung, der das Wohl der WorturheberInnen am Herzen liegt.
      Mehr über das Konzept erfährt man unter:
    </Text>
    <Text variant="default">
      <Styled.a
        href="https://shop.autorenwelt.de/about"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://shop.autorenwelt.de/about
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />
      <br />
      <Styled.a
        href="https://autorenprogramm.autorenwelt.de/page/faq"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://autorenprogramm.autorenwelt.de/page/faq
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />
    </Text>
    <Heading sx={{ pt: 5 }} as="h2">
      Das Team
    </Heading>
    <Grid variant="default" gap={4} columns={[2, null, 3]}>
      {content.photos.map(photo => (
        <Card sx={{ textAlign: "center" }}>
          <Img fluid={data[photo.imageName].childImageSharp.fluid} />
          <Heading sx={{ pt: 2, fontSize: 1 }} as="h3">
            {photo.title}
          </Heading>
          <Text sx={{ fontSize: 0 }}>{photo.description}</Text>
        </Card>
      ))}
    </Grid>
  </Layout>
)

export const query = graphql`
  query {
    boerbooms: file(relativePath: { eq: "team/boerbooms.png" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    angelika: file(relativePath: { eq: "team/angelika.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    anke: file(relativePath: { eq: "team/anke.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    sandra: file(relativePath: { eq: "team/sandra.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    heribert: file(relativePath: { eq: "team/heribert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
